import React, { useState, useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { set as setCookie } from 'js-cookie';
import useIsMounted from 'ismounted';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as Yup from 'yup';

import { useApiFetch } from '../hooks';
import { refreshPlayerMeta, setPlayerData } from '../store/player/actions';
import Yupmik from './yupmik';


function getResponseError(resp, hasCaptcha, t) {
  let formError = undefined;

  if (resp.hasOwnProperty('info')) {
    const { resultCode } = resp.info;

    switch (resultCode) {
      case 'invalid_username_or_password':
        formError = t('auth:loginError.invalidCredentials');
        break;

      case 'account_deactivated':
        formError = t('auth:loginError.acountDeactivated');
        break;

      case 'account_unactivated':
        formError = t('auth:loginError.activationRequired');
        break;

      case 'account_pending':
        formError = t('auth:loginError.oldPassword');
        break;

      case 'invalid_captcha':
        if (hasCaptcha) {
          formError = t('auth:loginError.invalidCaptcha');
        } else {
          formError = t('auth:loginError.captchaRequired');
        }
        break;

      default:
        break;
    }
  }

  return formError;
}

function anonKeySelector(s) {
  return s.player.anonKey;
}

function Login() {
  const { t } = useTranslation();
  const [ formErrors, setFormErrors ] = useState();
  const [ submitting, setSubmitting ] = useState(false);
  const { apiUserFetch } = useApiFetch();
  const dispatch = useDispatch();
  const anonKey = useSelector(anonKeySelector);
  const isMounted = useIsMounted();

  const [ showCaptcha, setShowCaptcha ] = useState(false);
  const doRefresh = useRef();

  const handleSubmit = useCallback(({ username, password, remember, captcha }) => {
    if (submitting) {
      return;
    }

    setFormErrors(undefined);
    setSubmitting(true);

    const data = { username, password };

    if (captcha) {
      data.captcha = captcha;
    }

    apiUserFetch(
      'player/login',
      {
        method: 'POST',
        data
      }
    )
      .then(response => {
        if (isMounted.current) {
          setSubmitting(false);
        }

        if (response.info && response.info.success) {
          const {
            sessionid: playerKey, username, level, email, address,
            birthdate, countrycode, currencycode, fname, lname,
            newsletter, id, city, phone, mobile
          } = response.data;

          dispatch(setPlayerData({
            id,
            playerKey,
            username,
            level,
            email,
            address,
            birthdate,
            countrycode,
            currencycode,
            fname,
            lname,
            newsletter,
            city,
            phone,
            mobile
          }));

          dispatch(refreshPlayerMeta());

          const cookieOptions = {};

          if (remember) {
            cookieOptions.expires = 7;
          }

          setCookie('anonKey', anonKey, cookieOptions);
          setCookie('playerKey', playerKey, cookieOptions);

          return;
        }

        return Promise.reject({ response });
      })
      .catch(error => {
        if (!isMounted.current) {
          return;
        }

        setSubmitting(false);

        setFormErrors({
          '__form__': getResponseError(error.response, !!data.captcha, t) || t('auth:loginError.default')
        });

        if (error.response?.data?.showCaptcha) {
          setShowCaptcha(true);
        }

        if (data.captcha) {
          doRefresh.current();
        }
      });
  }, [
    submitting, setFormErrors, setSubmitting, apiUserFetch,
    dispatch, anonKey, isMounted, t
  ]);

  const loginSchema = useMemo(() => {
    const shape = {
      username: Yup.string()
        .label(t('auth:login.usernameLabel'))
        .min(4)
        .max(20)
        .default('')
        .required()
        .matches(new RegExp(), t('forms:alphanumericOnlyError'))
        .meta({
          cols: 2
        }),
      password: Yup.string()
        .label(t('auth:login.passwordLabel'))
        .min(6)
        .required()
        .meta({
          password: true,
          cols: 2
        })
    };

    if (showCaptcha) {
      shape.captcha = Yup.string()
        .label(t('auth:signUp.captchaLabel'))
        .default('')
        .required()
        .meta({
          captcha: 'captcha?width=200&height=100',
          maxWidth: true,
          actions: doRefreshAction => {
            doRefresh.current = doRefreshAction;
          },
          cols: 1
        });
    }

    shape.remember = Yup.boolean()
      .label(t('auth:login.rememberMeLabel'))
      .default(false)
      .meta({
        textCenter: true,
        cols: 1
      });

    return Yup.object().shape(shape);
  }, [ t, showCaptcha ]);

  return (
    <Yupmik
      validationSchema={loginSchema}
      onSubmit={handleSubmit}
      submitting={submitting}
      errors={formErrors}
      submitText={t('auth:loginButton')}
    />
  );
};

export default Login;